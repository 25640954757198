// import logo from './logo.svg';
// import vineyardDay from './assets/vineyard-day.jpg';
import goldCornersTR from './assets/gold-corners/tr.png';
import goldCornersTL from './assets/gold-corners/tl.png';
import charactersFion from './assets/characters_fion.png';
import charactersNate from './assets/characters_nate.png';
import charactersLevory from './assets/characters_levory.png';
import charactersEmber from './assets/characters_ember.png';
import './App.css';
import Slider from './Slider.js';
import(/* webpackPreload: true */ './assets/webfontkit-tangerine/tangerine-regular-webfont.woff');
import(/* webpackPreload: true */ './assets/webfontkit-tangerine/tangerine-regular-webfont.woff2');

function App() {
  return (
    <div className="main-page">
      <div className="position-relative">
        <img src={goldCornersTL} width="20%" className="corner tl"></img>
        <img src={goldCornersTR} width="20%" className="corner tr"></img>
      </div>
      <div className="text-center text-magnified">
        <br/>
        <h4 className="announcement">“… For though all the stars in their millions may part them,<br/>the ones who are kind always find one another.”</h4>
        <h1 className="text-header">THE WANDERING</h1>
        <h2 className="text-header">Book I: The Highness, Then, of Camron</h2>
      </div>
      <Slider data={[
        {
          image: 'vineyard-day',
          text: [
            <>Levory whipped around. A girl in a silver crown and vermilion dress stood opposite him in the simulation. He bowed to her. “Good afternoon, Your Highness.”</>,
            <>She waved this off with a laugh. “Let’s not do the formalities. I’m Naia. You’re Lee-vory… is that how you pronounce it?”</>,
            <>“It's Leh-vory,” said Levory.</>,
            <>“Leh-vory.” Naia rolled the word around on her tongue, then smiled. “Well, nice to meet you, Leh-vory.”</>,
          ]
        },
        {
          image: 'sphera',
          text: [
            <>Sharp breaths were drawn all around.</>,
            <>It was as though they had entered through the side of an enormous disco ball, with people and buildings stuck to all sides of the walls and ceiling.</>,
            <>Sunlight flooded through long strips of windows by the poles. Up ahead, a river ran in an unbroken loop, dividing the Sphera into two vertical halves.</>,
            <>On the near riverbank, villages lay scattered across the earthy terrain, and on the far bank, a ring of high-rise office buildings stood pointing into the centre of the Sphera, like teeth in the maw of a great beast.</>,
          ]
        },
        {
          image: 'awaken-om-nivarra',
          text: [
            <>The white giant was glowing now, enshrined in a corona of light. Still Fion persisted, lifting a hand to shield herself from the blinding blaze.</>,
            <>“The real Nivarra was a giant among worlds,” she intoned in a voice deep and rich, one that was barely her own. <i>“The real Nivarra was a god among men!”</i></>,
            <>The humming gave way to a terrible groan, the sound of a million planets cracking open at once. And at last, Fion let her hand down and lowered her voice to a conceited whisper:</>,
            <><i>“Awaken Om Nivarra.”</i></>,
          ]
        },
        {
          image: 'mantis',
          text: [
            <>“… The harpy apologised for being so mean to the golden prince; everyone lived happily ever after, the end.”</>,
            <>“Good story. A good-d-d story,” said the Mantis; it said the same thing after every story that Nate told, even the ones with terribly shameless self-insertions.</>,
            <>Nate yawned. It was late in the afternoon, judging by the thinning rays of sun through the window. “Okay, Mr Mantis, it’s your turn to tell a story.”</>,
          ]
        },
        {
          image: 'pegasus',
          text: [
            <>“It’s a bird,” yelled someone.</>,
            <>“It’s a drone,” called another.</>,
            <>Bozeye Kurnick gazed out the window, tracing the arc of the flyer. An old man’s smile crept onto his face. “It’s a pegasus.”</>,
            <>The tiny pegasus landed on the great worm, and it was all over. The Selaramah gave a final and terrible screech—then it went still, its last spine held high in the trembling hands of Celeste Shaw.
            </>,
          ],
          unlock: {
            condition: 'KEY IN LAST WORD OF CHAPTER 22 TO UNLOCK',
            password: 'Shaw',
          },
        },
      ]}>
      </Slider>
      {/* <img src={vineyardDay} className="bg-image"></img> */}
      <br/>
      <hr className="style-eight"/>
      <div className="text-center text-magnified">
        <p><u>Blurb</u></p>
        <p>
          <b>THE HIGHNESS, THEN, OF CAMRON</b> is the first instalment in <b>THE WANDERING</b>, a teen space opera set thousands of years in the future.
          Enter a world of rivalling megacorporations and space kingdoms,
          fantastic mind palaces,
          zero-gravity sword fighting matches,
          and weird aliens who tell weirder prophecies…
        </p>
        <p>
          <b>Fion</b> and <b>Nate Stone</b> are thirteen-year-old twins living on the Waystation,
          an idyllic space habitat that sustains its own villages, cities, and farms.
        </p>
        <div className="flexbox">
          <img src={charactersFion} width="150px" height="200px" style={{ margin: '0 1%' }}></img>
          <p>
            Fion belongs to a group of brilliant savants known as <b>psiads</b>.
            She does space travel mathematics for Waycorp, the corporation that controls the portals connecting an intergalactic Federation of Worlds.
          </p>
        </div>
        <div className="flexbox">
          <p>
            Nate is, most decidedly, <i>not</i> a genius psiad.
            He just wants to survive his first year of high school at the Waystation Academy without getting bullied for his rare disorder, <b>Looper’s Malady</b>, which makes him blister on contact with dirty things.
          </p>
          <img src={charactersNate} width="150px" height="200px" style={{ margin: '0 1%' }}></img>
        </div>
        <p>
          One day, Fion is challenged by the powerful director of Waycorp, Mr Nira,
          to solve a difficult mathematical problem called a <b>Locked Phoenix</b> equation—if she succeeds,
          she will be inducted into a secret society who can help her free her wrongfully imprisoned father.
          Meanwhile, Nate befriends two new students at the Waystation Academy:
        </p>
        <div className="flexbox">
          <img src={charactersLevory} width="150px" height="200px" style={{ margin: '0 1%' }}></img>
          <p>
            <b>Levory Long</b> is a transfer student with superhuman healing abilities. He is a <b>chimera</b>, which means that his DNA is partly human, and partly something called beastbane.
          </p>
        </div>
        <div className="flexbox">
          <p>
            <b>Ember Graves</b> is a girl with a mysterious past. She can’t remember anything before several weeks ago, when she was rescued off a hunk of rock drifting through space by Matron Anastasi, the stern head of the Academy.
          </p>
          <img src={charactersEmber} width="150px" height="200px" style={{ margin: '0 1%' }}></img>
        </div>
        <p>
          But to everyone’s surprise, Levory recognises Ember as a princess from the faraway Space Kingdom of Camron, whom he escorted to a ball last summer.
          Since then, he explains, the wicked Grand Premier of Camron has killed her father and seized control of her kingdom.
        </p>
        <p>
          The four children piece together clues to help Ember remember how she escaped Camron, but tensions come to a boil when Matron Anastasi presents evidence Levory might be an undercover assassin hired by the Grand Premier to hunt Ember.
        </p>
        <p>
          As accusations start flying and snakes in the grass reveal themselves, the children realise they’ve been swept up in a dangerous plot involving royal murders, a cult of bounty-hunting assassins, and that most curious Locked Phoenix equation…
        </p>
        {/* <p>
          The cynical school headmistress, Matron Anastasi, claims to have proof that Levory is an assassin looking to claim a bounty on Ember.
          It turns out that Ember used to be a princess from the faraway Space Kingdom of Camron, before the wicked Grand Premier of Camron killed her father and seized the kingdom for himself a year ago.
        

          However, not all is as it seems.
          The children soon learn that Ember is a princess-on-the-run from the faraway Space Kingdom of Camron.
          An infamous band of assassins is hunting her—and for some reason,
          the cynical school headmistress, Matron Anastasi, is hell-bent on proving that Levory is among their company.
          As things turn on their head, Fion comes to the bizarre realisation that Mr Nira’s equation might lie at the centre of everyone’s troubles…
        </p> */}
      </div>
      <br/>
      <hr/>
      <div className="footer">
        <p>Author: <a href="https://www.instagram.com/arnoldtanjunhan/">@arnoldtanjunhan</a></p>
        <p>Illustrations: <a href="https://www.instagram.com/itsnicholashere/">@itsnicholashere</a></p>
      </div>
      <br/>
    </div>
  );
}

export default App;
