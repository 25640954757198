import { useState } from 'react';

function Slider({ data = [{ image: '', text: [<></>] }] }) {
  const [index, setIndex] = useState(0);
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [touchStart, setTouchStart] = useState(undefined);
  const [touchEnd, setTouchEnd] = useState(undefined);

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
    setTouchEnd(undefined);
  }
  
  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  // function handleMouseStart(e) {
  //   setTouchStart(e.clientX);
  // }
  
  // function handleMouseMove(e) {
  //   setTouchEnd(e.clientX);
  // }

  function handleSlideEnd() {
    if (touchEnd === undefined) return;
    if (touchStart - touchEnd > 50) {
      // left swipe
      nextSlide();
    }
  
    if (touchStart - touchEnd < -50) {
      // right swipe
      prevSlide();
    }
  }

  function nextSlide() {
    console.log('on to next slide!');
    index < data.length - 1 ? setIndex(index + 1) : setIndex(0);
  }

  function prevSlide() {
    console.log('back to previous slide!');
    index > 0 ? setIndex(index - 1) : setIndex(data.length - 1);
  }

  const slide = data[index];
  const { unlock } = slide;
  const shouldPromptPassword = unlock?.condition && localStorage.getItem(slide.image) !== unlock?.password;
  return (
    <>
      <div
        className={`bg ${shouldPromptPassword ? 'no-image' : slide.image}`}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleSlideEnd}
        // onMouseDown={handleMouseStart}
        // onMouseMove={handleMouseMove}
        // onMouseUp={handleSlideEnd}
      >
        <div/>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          {data.length > 0 && <button className="slider-button wiggle-on-hover" onClick={prevSlide}>{`<<`}</button>}
          {
            shouldPromptPassword
              && <div>
                <p>{unlock?.condition}</p>
                <input
                  type="text"
                  value={input}
                  onChange={e => setInput(e.target.value)}
                  onFocus={() => setError('')}
                  className="slider-input"
                />
                <button
                  onClick={() => {
                    localStorage.setItem(slide.image, input);
                    if (input !== unlock?.password) setError('Incorrect, try again!');
                    setInput('');
                  }}
                  className="slider-button"
                >
                  Submit
                </button>
                {error && <p style={{ color: 'darkred', position: 'absolute' }}>{error}</p>}
              </div>
          }
          {data.length > 0 && <button className="slider-button wiggle-on-hover" onClick={nextSlide}>{`>>`}</button>}
        </div>
        <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-around', backgroundColor: 'rgb(0, 0, 0, 0.3)', borderRadius: '10%', padding: '0 1%', width: '25%', }}>
          {data.map((el, idx) => <p
            key={idx}
            style={{
              color: idx === index ? 'gold' : 'white',
              fontSize: '2em',
              margin: '0',
              cursor: 'pointer',
            }}
            onClick={() => setIndex(idx)}
          >
            •
          </p>)}
        </div>
      </div>
      <div className="text-center">
        {
          shouldPromptPassword
            ? <></>
            : slide.text.map((el, index) => <p key={index}>{el}</p>)
        }
      </div>
    </>
  );
}

export default Slider;
