// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../webfontkit-tangerine/tangerine-bold-webfont.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "../webfontkit-tangerine/tangerine-bold-webfont.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "../webfontkit-tangerine/tangerine-regular-webfont.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "../webfontkit-tangerine/tangerine-regular-webfont.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 9, 2021 */\n\n\n\n@font-face {\n    font-family: 'tangerinebold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n         url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'tangerineregular';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'),\n         url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n", "",{"version":3,"sources":["webpack://src/assets/stylesheets/tangerine.css"],"names":[],"mappings":"AAAA,mFAAmF;;;;AAInF;IACI,4BAA4B;IAC5B;+DAC8E;IAC9E,mBAAmB;IACnB,kBAAkB;;AAEtB;;;;;AAKA;IACI,+BAA+B;IAC/B;+DACiF;IACjF,mBAAmB;IACnB,kBAAkB;;AAEtB","sourcesContent":["/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 9, 2021 */\n\n\n\n@font-face {\n    font-family: 'tangerinebold';\n    src: url('../webfontkit-tangerine/tangerine-bold-webfont.woff2') format('woff2'),\n         url('../webfontkit-tangerine/tangerine-bold-webfont.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'tangerineregular';\n    src: url('../webfontkit-tangerine/tangerine-regular-webfont.woff2') format('woff2'),\n         url('../webfontkit-tangerine/tangerine-regular-webfont.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
